import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const GorgiasHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      heading="How Gorgias continuously improves the flow of delivery"
      company="gorgias"
      subheading="Alex Plugaru, CO-Founder, CTO at Gorgias, tells how Swarmia helps his teams reduce cycle time to improve flow of delivery."
      featured={featured}
    />
  )
}

export default GorgiasHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/gorgias/hero-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
