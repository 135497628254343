import React from 'react'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import GorgiasBody from '../../components/story/gorgias/Content'
import GorgiasHeader from '../../components/story/gorgias/Header'
import GorgiasResults from '../../components/story/gorgias/Results'
import MetaImage from '../../assets/images/story/gorgias/gorgias-cover.png'

const GorgiasPage = () => {
  return (
    <Layout
      title="Gorgias case study"
      description="How Gorgias improved software development processes"
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <GorgiasHeader />
        <GorgiasResults />
        <GorgiasBody />
      </CaseStudyContainer>
      <CTASection>
        Unblock the flow. Join&nbsp;the&nbsp;1,000+ teams using Swarmia today.
      </CTASection>
    </Layout>
  )
}
export default GorgiasPage
